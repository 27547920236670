import React, { MouseEventHandler, KeyboardEventHandler } from 'react';
import RawLink from 'next/link';

interface Props {
    readonly to: string;
    readonly className?: string;
    readonly active?: boolean;
    readonly activeClassName?: string;
    readonly children?: any;
    readonly target?: string;
    readonly rel?: string;
    readonly onKeyUp?: KeyboardEventHandler;
    readonly onClick?: MouseEventHandler;
}

function Link({
    to,
    className = '',
    active = false,
    activeClassName = '',
    children = null,
    target = '',
    rel = '',
    onClick = (_event) => { },
    onKeyUp = (_event) => { },
    ...props
} : Props) {
    const finalClass = `${className} ${active && activeClassName}`;
    return (
        <RawLink
            className={finalClass}
            href={to}
            target={target}
            onClick={onClick}
            onKeyUp={onKeyUp}
            rel={rel}
            {...props}
        >
            {children}
        </RawLink>
    );
}

export default Link;
