import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { LiveChatLoaderProvider, HelpScout } from 'react-live-chat-loader';

import { userAtom, serverDownErrorAtom } from '@/components/with_auth/State';
import { isOnboardingCompleted, isOnboardingNow } from '@/components/onboarding/Shared';
import NetworkError from '@/components/commons/network_error';
import routes from '@/services/routes';

import Header from './Header';
import AppSidebar from './commons/app_sidebar';

declare global {
    interface Window {
        Beacon: any
    }
    // eslint-disable-next-line vars-on-top,no-var
    var window: Window & typeof globalThis;
}

export default function Layout({ children }) {
    const router = useRouter();
    const user = useRecoilValue(userAtom);
    const serverDownError = useRecoilValue(serverDownErrorAtom);
    const [onboardingComplete, setOnboardingComplete] = useState(false);
    const [beaconLoaded, setBeaconLoaded] = useState(false);
    const [atOnboardingPath, setAtOnboardingPath] = useState(false);
    useEffect(() => {
        if (!router) {
            return;
        }

        const isOnboardingPath = isOnboardingNow(router);

        let onboardingCompleted = false;
        if (user) {
            onboardingCompleted = isOnboardingCompleted(user);
        }
        setAtOnboardingPath(isOnboardingPath);
        setOnboardingComplete(onboardingCompleted && !isOnboardingPath);
    }, [router, user]);

    useEffect(() => {
        if (!beaconLoaded || !user || !window || !window.Beacon) {
            return;
        }

        const createdAtRaw = user.createdAt;
        let createdAt;
        if (createdAtRaw) {
            [createdAt] = createdAtRaw.split('T');
        }

        // to track user / update their info
        window.Beacon('identify', {
            name: user.fullName,
            email: user.email,
            'customer-id': user.id,
            industry: user.industry,
            occupation: user.occupation,
            role: user.role,
            'timezone-name': user.timezoneName,
            'timezone-offset': user.timezoneOffset,
            'customer-since': createdAt,
        });
    }, [beaconLoaded, user]);

    const atBioPath = router.asPath.startsWith(routes.BIO_PREFIX);
    const atBriefPath = router.asPath.startsWith(routes.BRIEF_PREFIX);

    return (
        <div>
            {(onboardingComplete || !atOnboardingPath) && !atBriefPath && <Header />}

            <div className="flex flex-row">
                <div className="hidden ">
                    <AppSidebar
                        isLoggedIn={(user?.id && onboardingComplete && !atBriefPath)
                            || (user?.id && !atOnboardingPath && !atBioPath && !atBriefPath)}
                    />
                </div>
                <main className={`${(onboardingComplete || !atOnboardingPath) && !atBriefPath ? 'h-full bg-lightGray' : 'p-5 md:pt-10 sixty-mountain h-full min-h-[100vh]'} w-full overflow-x-clip`}>
                    <div className="">
                        {serverDownError ? (
                            <NetworkError />
                        ) : children}
                    </div>
                </main>
            </div>

            <LiveChatLoaderProvider
                providerKey={process.env.NEXT_PUBLIC_HELPSCOUT_KEY}
                provider="helpScout"
                onReady={() => {
                    setBeaconLoaded(true);
                }}
            >
                <HelpScout icon="message" color="green" zIndex="999" horizontalPosition="right" />
            </LiveChatLoaderProvider>
        </div>
    );
}
