import React from 'react';

import routes from '@/services/routes';

import styles from './NetworkError.module.css';

function NetworkError() {
    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <div className={styles.formContent}>
                    <div>
                        <h2 className={styles.mainMessage}>
                            Network Error
                        </h2>
                    </div>

                    <div>
                        <p className="p-4">
                            We&apos;re having trouble talking to the Sixty servers.
                            <br />
                            <br />
                            If you are connected to the Internet, our engineering team has been
                            notified of the problem.
                            <br />
                            <br />
                            Please confirm your network connection is working properly, and try
                            reloading the page.
                            <br />
                            <br />
                            You can check our system status
                            {' '}
                            <a className={styles.link} target="_blank" rel="noreferrer" href={routes.STATUS_PAGE}>
                                here
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NetworkError;
