import { useRouter } from 'next/router';

export default function useURL() {
    const router = useRouter();
    if (router) {
        return router.query;
    }

    return {};
}
