import React from 'react';
import { useRouter } from 'next/router';

import routes from '@/services/routes';
import Link from '@/components/commons/link';

import styles from './SiteNavigation.module.css';

function SiteNavigation({
    onClick = (_event) => { },
}) {
    const router = useRouter();

    return (
        <Link
            to={routes.LOGIN}
            className={`${styles.inactive} linkLike--secondary`}
            active={router.asPath === routes.LOGIN}
            activeClassName={styles.active}
            onClick={onClick}
        >
            Login

        </Link>
    );
}

export default SiteNavigation;
