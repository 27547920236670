import React, { ReactElement } from 'react';

interface Props {
    readonly svg: ReactElement;
    readonly className?: string;
}

export default function IconWrapper({
    svg,
    className = null,
}: Props) {
    const baseClassName = 'flex dark:text-cirrusWhite';
    return (
        <div className={`${className} ${baseClassName}`}>
            {svg}
        </div>

    );
}
