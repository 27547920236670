import * as Sentry from '@sentry/nextjs';
import {
    ApolloClient,
    ApolloError,
    InMemoryCache,
    from,
    createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { sentenceCase } from 'change-case';
import fetch from 'cross-fetch';

export function transformErrors(errors) {
    const result = {};

    errors.forEach(({ path, message }) => {
        let target = result;
        // let lastElem = ''; // N.B. Column name, which we no longer use.
        let lastTarget = [];
        path.forEach((elem, idx) => {
            if (idx === path.length - 1) {
                target[elem] = target[elem] || [];
            } else {
                target[elem] = target[elem] || {};
            }
            lastTarget = target[elem];
            target = lastTarget;
            // lastElem = elem;
        });
        lastTarget.push(sentenceCase(message, { stripRegexp: /^$/ }));
    });

    return result;
}

export function handleValidationErrors(rawErrors, setStatus, attributeType) {
    if (rawErrors && rawErrors.length > 0) {
        const errors = transformErrors(rawErrors)['input'].attributes[attributeType]; // eslint-disable-line dot-notation
        setStatus({ errors });
    }
}

export function handleFetchErrors(fetchingError) {
    Sentry.captureException(fetchingError);
}

export function constructMutation(mutationName, inputType, innerMutationName, mutationBody, items) {
    const itemsLenght = items.length;

    if (itemsLenght === 0) return null;

    let mutation = `mutation ${mutationName}(`;

    items.forEach((_item, index) => {
        mutation += `$input${index}: ${inputType}`;
    });

    mutation += ') { ';

    items.forEach((_item, index) => {
        mutation += `alias${index}: ${innerMutationName}(input: $input${index}) {
            ${mutationBody}
        }`;
    });

    mutation += '}';

    return mutation;
}

export function constructMutationVariables(items, withAttributes, fullAttributes) {
    const variables = {};
    if (items.length === 0) return variables;

    items.forEach((item, index) => {
        if (withAttributes) {
            variables[`input${index}`] = {
                id: item,
                attributes: fullAttributes,
            };
        } else {
            variables[`input${index}`] = {
                id: item,
            };
        }
    });

    return variables;
}

export function onCompletedHandler(setIsError, callback) {
    return (data) => {
        try {
            callback(data);
        } catch (exc) {
            // eslint-disable-next-line no-console
            console.log('Data:', data);
            if (setIsError) {
                setIsError(true);
            }
            Sentry.captureException(exc);
        }
    };
}

export function onErrorHandler(setIsError = null, callback = null) {
    return (err : ApolloError) => {
        try {
            // eslint-disable-next-line no-console
            console.log('ApolloError:', err);
            if (setIsError) {
                setIsError(true);
            }
            Sentry.captureException(err);
            if (callback) {
                callback(err);
            }
        } catch (exc) {
            // eslint-disable-next-line no-console
            console.log('Exception:', exc);
            Sentry.captureException(exc);
        }
    };
}

const httpLink = createHttpLink({
    credentials: 'include',
    uri: (_) => {
        if (window?.location?.hostname === process.env.NEXT_PUBLIC_FRONTEND_LINK_DOMAIN) {
            return process.env.NEXT_PUBLIC_LINK_ENDPOINT;
        }
        return process.env.NEXT_PUBLIC_BACKEND_ENDPOINT;
    },
    fetch,
});

const errorRecorderLink = onError(({ networkError, graphQLErrors }) => {
    // TODO: Extract and do something with graphQLErrors?
    // eslint-disable-next-line no-console
    console.log('onError', networkError, graphQLErrors);

    // N.B. Apparently `Sentry.captureException` is not a thing anymore.
    // if (networkError) Sentry.captureException(networkError);
});

const errorEnricherLink = new SentryLink({
    // setTransaction: true,
    attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeFetchResult: false, // A bit voluminous, and not included on errors.
        includeError: true,
    },
});

export const client = new ApolloClient({
    link: from([errorRecorderLink, errorEnricherLink, httpLink]),
    cache: new InMemoryCache(),
});
