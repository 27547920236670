import { gql } from '@apollo/client';

import { client } from '@/services/apollo';
import { setSentryUserScope } from '@/services/sentry';
import { type RecoilUserSettersType, COMMON_USER_FIELDS } from '@/types/globals';
import { transformException } from '@/components/onboarding/Actions';

const SIGNED_IN = gql`
    query SignedIn {
        signedIn
        user {
            ${COMMON_USER_FIELDS}
        }
    }
`;

const SIGN_OUT = gql`
    mutation SignOut {
        signOut(input:{}) {
            errors {
                path
                message
            }
        }
    }
`;

const FETCH_USER = gql`
    query UserInfo {
        user {
            ${COMMON_USER_FIELDS}
        }
    }
`;

const signOut = async (setters: RecoilUserSettersType, cb: (data: any, error: any) => void) => {
    try {
        setters.setUser(null);
        setters.setInitialized(true);
        const response = await client.mutate({ mutation: SIGN_OUT });
        if (response) {
            if (!response.data.signOut.errors || response.data.signOut.errors.length === 0) {
                setSentryUserScope(null);
            }
        }
    } catch (error) {
        cb(false, transformException(error));
    }
};

export {
    SIGNED_IN, FETCH_USER, signOut,
};
