import React from 'react';
import { useRecoilValue } from 'recoil';

import Gravatar from 'react-gravatar';
import MD5 from 'crypto-js/md5';
import { userAtom } from '@/components/with_auth/State';

import styles from './AppProfile.module.css';
import Menu from './Menu';

function AppProfile({
    isOpen = false,
    onProfileClick = () => { },
    onOpen = () => { },
    onSignOut = () => { },
}) {
    const { email } = useRecoilValue(userAtom);

    const handleCancelKeyUp = (event) => {
        if (event.key === 'Escape' && isOpen) {
            onProfileClick();
        }
    };

    return (
        <div>
            <div className={styles.activateButtonContainer}>
                <button
                    className={`flex flex-row items-center ${styles.activateButton}`}
                    type="button"
                    onClick={onProfileClick}
                    onKeyUp={handleCancelKeyUp}
                    aria-label="User Menu"
                >
                    <Gravatar
                        id="user-menu"
                        md5={MD5(email).toString()}
                        size={36}
                        default={`${process.env.NEXT_PUBLIC_FRONTEND_ROOT}/images/user-fallback.png`}
                        style={{
                            borderRadius: '50%',
                            ariaExpanded: isOpen,
                            ariaHaspopup: false,
                        }}
                    />
                </button>
            </div>

            {isOpen && (
                <div className={styles.backstop} aria-hidden onClick={onProfileClick}>
                    &nbsp;
                </div>
            )}

            <Menu
                isOpen={isOpen}
                onProfileClick={onProfileClick}
                onOpen={onOpen}
                onSignOut={onSignOut}
                onCancelKeyUp={handleCancelKeyUp}
            />
        </div>
    );
}

export default AppProfile;
