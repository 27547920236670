import React, { MouseEventHandler } from 'react';

import useResize from '@/hooks/useResize';
import { MAX_FULLWIDTH_RES } from './Constants';

import Sidebar from './Sidebar';

interface Props {
    readonly isOpen: boolean;
    readonly onClick: MouseEventHandler;
}

export default function Drawer({
    isOpen,
    onClick,
}: Props) {
    const [width] = useResize();
    const drawerPosition = 'flex flex-col px-6 pt-2 pb-6 bottom-0 left-0 fixed overflow-auto';
    const drawerAnimation = `transform ease-in-out transition-all duration-300 z-30 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`;
    const drawerSize = `${width < MAX_FULLWIDTH_RES ? 'w-full' : 'w-4/5'} h-[calc(100%-84px)]`;

    return (
        <aside
            className={`${drawerAnimation} ${drawerPosition} ${drawerSize} bg-cleanSlate `}
        >
            <Sidebar onClick={onClick} />
        </aside>
    );
}
