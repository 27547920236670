import React, { useEffect } from 'react';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { Toaster } from 'react-hot-toast';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import * as Fathom from 'fathom-client';

import useURL from '@/hooks/use_url';
import { client } from '@/services/apollo';
import Layout from '@/components/Layout';
import '../styles/globals.css';

if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Loading Apollo error messages.  This should only happen in development!');
    loadDevMessages();
    loadErrorMessages();
}

function WithLayout({ Component, pageProps }) {
    // TODO: Make <Layout> opt-in, so that we don't include the Layout code for every single page,
    // TODO: whether it's used or not.
    const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);
    return getLayout(<Component {...pageProps} />);
}

function App(properties) {
    const router = useRouter();
    const source = useURL().source as string;

    useEffect(() => {
        if (source !== undefined) {
            localStorage.setItem('source', source);
        }
    }, [source]);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_FATHOM_SITE === undefined) {
            return undefined;
        }

        Fathom.load(process.env.NEXT_PUBLIC_FATHOM_SITE, {
            includedDomains: [process.env.NEXT_PUBLIC_FRONTEND_DOMAIN],
        });

        function onRouteChangeComplete() {
            Fathom.trackPageview();
        }

        router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, [router.events]);

    // N.B. You may see `nonce=""` in the script tag below. That may seem nonsensical, but it's
    // necessary.  Yes, things will continue working if you remove it -- in development.  But in
    // stage/prod, some things (like HelpScout, when we loaded it directly) may silently break.
    // No errors, assets load -- but no outcome.  The _reason_ for this is that we use a CloudFlare
    // Worker to _rewrite the HTML_, and so when Next tries to rehydrate React state, the HTML is
    // _different than when it was rendered_ (it doesn't seem to look at the values of attributes,
    // just the values -- at lease for `nonce`).  The end result is that the <script> element below
    // is _dirty_ and this results in a re-render of it.  The re-render triggers execution of the
    // script, which in turn results in unexpected behavior.  In the case of HelpScout,
    // `if(e.Beacon=...` was being executed a second time, relatively early in the initialization
    // process.  And so we wound up with a pre-initialization window.Beacon stub replacing the fully
    // initialized version.
    //
    // This may or may not be an issue for other things, but the bottom line is that anything we're
    // putting in the <head> directly probably expects to only get executed once and breaking that
    // assumption is a bad idea.
    //
    // tl;dr:  Leave any `nonce=""` below alone!

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <link rel="apple-touch-icon" sizes="180x180" href="/favicon-180x180.png?v=1" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=1" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=1" />
                <meta name="msapplication-TileImage" content="/favicon-144x144.png?v=1" />
                <meta name="msapplication-config" content="/browserconfig.xml?v=1" />
                <meta name="theme-color" content="white" />

                {/* manifest.json provides metadata used when your web app is installed on a
                user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
                <link rel="manifest" href="/manifest.json?v=1" />
                <link rel="shortcut icon" href="/favicon.ico?v=1" />

                <title>Sixty</title>
            </Head>
            <ApolloProvider client={client}>
                <RecoilRoot>
                    <WithLayout {...properties} />
                </RecoilRoot>
            </ApolloProvider>
            <Toaster />
        </>
    );
}

export default App;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
