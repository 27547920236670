export interface RecoilUserSettersType {
    setUser: (user: any) => void;
    setInitialized: (initialized: boolean) => void;
}

export interface bioPageProps {
    openBrief: {open: boolean, briefIndex: any};
    openModal?: (step: number, isEdit: boolean) => void;
    isModal: boolean;
    isEdit: boolean;
    extendContinue: ((selection?: string, review?: boolean) => void) | null;
    overrideBack: (skipped: boolean) => void | null;
}

export const COMMON_BIO_CANDIDATE_FIELDS = `
    bio
    createdAt
    documentsSubset
    id
    status
    updatedAt

`;

export const COMMON_BIO_BATCH_FIELDS = `
    batchJobId
    candidates {
        ${COMMON_BIO_CANDIDATE_FIELDS}
    }
    createdAt
    updatedAt
    id
    status
    documents
`;

export const COMMON_WORK_HISTORY_ITEM_FIELDS = `
    id
    organization
    description
    title
    startAt
    endAt
    createdAt
    updatedAt
`;

export const COMMON_BRIEF_FIELDS = `
    name
    biography
    birthday
    canonicalKey
    communicationPreferences
    company
    createdAt
    displayKey
    displayWorkHistory
    emailHash
    fullName
    id
    industry
    location
    meetingPreferences
    occupation
    profileImageUrl
    relationshipStatus
    role
    skippedBioGeneration
    socialLinks
    title
    updatedAt
    randomlySelected

    userId

    bioBatch {
        ${COMMON_BIO_BATCH_FIELDS}
    }

    workHistoryItems {
        ${COMMON_WORK_HISTORY_ITEM_FIELDS}
    }
`;

export const COMMON_USER_FIELDS = `
    id
    state
    deleteAt
    email
    emailHash
    birthday
    productIds
    fullName
    heardFrom
    industry
    occupation
    onboardingState
    recognizeSameDomainUsers
    enableArchival
    slowArchivalPeriod
    fastArchivalPeriod
    enableYesterdaysInbox
    role
    timezoneName
    timezoneOffset
    receiveProductUpdates
    birthdayEmailFrequency
    birthdayEmailMaxBirthdays
    birthdayEmailReminderDays
    birthdayEmailContactType
    birthdayEmailSort
    vacationMode
    createdAt
    expectedUsageKind
    expectedUsageOther
    hasCreatedBio
    hasCreatedAlerts
    hasCreatedAgendas
    hasReviewedCalendars
    hasCreatedFolders
    setupReminderAt
    title
    usesSuperhuman
    usesSanebox
    bioOnly
    location
    socialLinks
    company
    profileImageUrl
    briefs {
        ${COMMON_BRIEF_FIELDS}
    }
    documents {
        name
    }
    workHistoryItems {
        ${COMMON_WORK_HISTORY_ITEM_FIELDS}
    }
`;

export const COMMON_ACCOUNT_FIELDS = `
    id
    type
    name
    email
    kind

    active
    enabled
    suspended
    authorized

    createdAt
    updatedAt
`;

export const COMMON_CALENDAR_INSTANCE_FIELDS = `
    id

    effectiveLabel
    label
    summary

    categories

    visible

    primary
    writable

    account {
        name
        type
    }

    createdAt
    updatedAt
`;
