globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"p8x_X0PBC2McNiPtItiTm"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { escapeRegExp } from './services/regex';

const env = process.env.NEXT_PUBLIC_ENV || 'unknown';
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (env !== 'development' && env !== 'unknown') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: env,
        autoSessionTracking: true,

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,

        allowUrls: [
            new RegExp(`^${escapeRegExp(process.env.NEXT_PUBLIC_FRONTEND_ROOT)}`, 'i'),
            new RegExp(`^${escapeRegExp(process.env.NEXT_PUBLIC_BACKEND_ROOT)}`, 'i'),
        ],

        // Setting this option to true will print useful information to the console while you're
        // setting up Sentry.
        debug: false,

        replaysOnErrorSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // You can remove this option if you're not planning to use the Sentry Session Replay
        // feature:
        integrations: [
            Sentry.replayIntegration({
                // Additional Replay configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
    });
}
