import React from 'react';

import routes from '@/services/routes';

function AppIcon() {
    return (
        <a href={routes.ROOT} className="linkLike--secondary flex mr-[12px]">
            <img
                className="mx-auto"
                title="Sixty"
                alt="Sixty"
                src="/logo/LOGO_MARK.svg"
                width={38}
                height={39}
            />
        </a>
    );
}

export default AppIcon;
