import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

import routes from '@/services/routes';
import Link from '@/components/commons/link';
import Button, { ButtonSize, ButtonType } from '@/components/commons/button';

import styles from './AppProfile.module.css';

function Menu({
    isOpen = false,
    onProfileClick = () => { },
    onOpen = () => { },
    onSignOut = () => { },
    onCancelKeyUp = (_event) => { },
}) {
    const [handledOpen, setHandledOpen] = useState(false);

    useEffect(() => {
        if (isOpen && !handledOpen) {
            setHandledOpen(true);
            onOpen();
        } else if (!isOpen) {
            setHandledOpen(false);
        }
    }, [isOpen, onOpen, handledOpen, setHandledOpen]);

    const handleSignOut = () => {
        onSignOut();
        onProfileClick();
    };

    return (
        <Transition
            show={isOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <div
                className={`${styles.dropdown} border-gray-300`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
            >
                <div className={styles.linksContainer}>
                    <Link
                        to={routes.SETTINGS}
                        onKeyUp={onCancelKeyUp}
                        onClick={onProfileClick}
                        className={styles.navLink}
                    >
                        Profile
                    </Link>

                    <Link
                        to={routes.ACCOUNTS}
                        onKeyUp={onCancelKeyUp}
                        onClick={onProfileClick}
                        className={styles.navLink}
                    >
                        Authorized Accounts
                    </Link>
                </div>

                <hr className={styles.divider} />

                <div className={styles.linksContainer}>
                    <Button
                        externalStyles={styles.signOut}
                        size={ButtonSize.base}
                        text="Sign out"
                        onClick={handleSignOut}
                        onKeyUp={onCancelKeyUp}
                        type={ButtonType.transparent}
                    />
                </div>
            </div>
        </Transition>
    );
}

export default Menu;
