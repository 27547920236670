import React, { JSX } from 'react';

import styles from './Button.module.css';

export enum ButtonSize {
    base = 'base',
    extraSmall = 'extraSmall',
    small = 'small',
    large = 'large',
}

export enum ButtonType {
    primary = 'primary',
    secondary = 'secondary',
    transparent = 'transparent',
    plain = 'plain',
    submit = 'submit',
    nav = 'nav',
}

interface Props {
    readonly disabled?: boolean,
    readonly externalStyles?: string,
    readonly full?: boolean,
    readonly icon?: any,
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    readonly onKeyUp?: (event: React.KeyboardEvent<HTMLButtonElement>) => void,
    readonly size?: ButtonSize,
    readonly text?: string | JSX.Element,
    readonly type?: ButtonType,
    readonly tip?: string,
    readonly onboardingTip?: string,
    readonly tipContent?: string,
    readonly title?: string,
    readonly form?: string,
}

function Button({
    disabled = false,
    externalStyles = '',
    full = false,
    icon = null,
    onClick = (_event) => { },
    onKeyUp = (_event) => { },
    size = ButtonSize.base,
    text = 'button',
    type = ButtonType.primary,
    tip = null,
    onboardingTip = null,
    tipContent = null,
    title = '',
    form = null,
} : Props) {
    const sizeClass = `size--${size}`;
    let finalClass = `${styles.button} ${styles[sizeClass]} `;

    let buttonType = type;
    if (type === 'submit') {
        buttonType = ButtonType.primary;
    }

    const typeClass = `type--${buttonType}`;

    finalClass += `${styles[typeClass]} `;

    if (full) {
        finalClass += `${styles.full} `;
    }

    if (disabled) {
        finalClass += `${styles.disabled} `;
    }

    finalClass += `${externalStyles}`;
    finalClass = finalClass.trim();

    let content = text;
    if (icon) {
        content = (
            <div className="flex items-center justify-center sm:justify-start">
                <div className="flex-1">
                    {React.cloneElement(icon, { className: `inline align-middle mr-2 ${icon.props.className}` })}
                    {' '}
                    {text}
                </div>
            </div>
        );
    }

    return (
        <button
            className={finalClass}
            type={(type === 'submit') ? 'submit' : 'button'}
            onClick={onClick}
            onKeyUp={onKeyUp}
            disabled={disabled}
            data-tooltip-id={tip}
            data-tooltip-content={tipContent}
            data-onboarding={onboardingTip}
            title={title}
            form={form}
        >
            {content}
        </button>
    );
}

export default Button;
