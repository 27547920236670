import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { isOnboardingCompleted, isOnboardingNow, reportGeneralError } from '@/components/onboarding/Shared';
import routes from '@/services/routes';
import { signOut } from '@/components/with_auth/Actions';
import { initializedAtom, userAtom } from '@/components/with_auth/State';
import Bars from '@/components/commons/icons/Bars';
import Link from '@/components/commons/link';
import CloseIcon from '@/components/commons/icons/CloseIcon';
import AppIcon from './commons/AppIcon';
import HeaderMenu from './commons/header_menu/HeaderMenu';
import AppDrawer from './commons/app_sidebar/Drawer';
import AppProfile from './commons/app_profile';
import SiteNavigation from './commons/site_navigation';
import EnvelopeSolid from './commons/icons/EnvelopeSolid';

import styles from './Header.module.css';

function Header() {
    const router = useRouter();

    const setInitialized = useSetRecoilState(initializedAtom);
    const [user, setUser] = useRecoilState(userAtom);

    const setters = {
        setInitialized, setUser,
    };

    const signOutFunc = async () => {
        await signOut(setters, (success, data) => {
            if (!success) {
                reportGeneralError(data);
                return;
            }

            router.push(routes.ROOT);
        });
    };

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [onboarded, setOnboarded] = useState(false);

    const onboardedUser = !!user && onboarded;

    useEffect(() => {
        if (router) {
            const isOnboardingPath = isOnboardingNow(router);
            let onboardingCompleted = false;
            if (user) {
                onboardingCompleted = isOnboardingCompleted(user);
            }
            setOnboarded(onboardingCompleted && !isOnboardingPath);
        }
    }, [router, user]);

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(() => !mobileMenuOpen);
    };

    const handleProfileOpen = () => {
        setProfileOpen(() => !profileOpen);
    };

    const closeOthers = () => { };

    return (
        <nav className="bg-cirrusWhite z-20 relative pl-[25px] lg:pl-[50px]">
            <div className="pr-2 lg:pr-6 xl:pr-8">
                <div className=" flex items-center justify-between h-[9vh] shadow-sm">
                    <div className="flex-1 flex md:items-stretch md:justify-start">
                        <div className="flex-shrink-0 flex items-left pl-0 lg:pl-12">
                            <AppIcon />
                            <HeaderMenu />
                        </div>
                        {(onboardedUser || !user) && (
                            <div className="flex items-center md:hidden">
                                <button type="button" onClick={handleMobileMenuOpen} className="buttonLike--tertiary">
                                    {mobileMenuOpen ? <CloseIcon className="h-[16px] w-[25px] text-cleanSlate" /> : <Bars className="h-[25px] w-[25px] text-cleanSlate" />}
                                </button>
                            </div>
                        )}
                    </div>

                    {onboardedUser && (
                        <div className="inset-y-0 right-[30px] flex items-right">
                            <Link to={routes.INVITE_FRIENDS} className={styles.inviteButton}>
                                <EnvelopeSolid className="h-3 w-3 inline mr-1 mb-0.5" />
                                Invite friends
                            </Link>
                            <div className={styles.frontmost}>
                                <AppProfile
                                    isOpen={profileOpen}
                                    onProfileClick={handleProfileOpen}
                                    onOpen={closeOthers}
                                    onSignOut={signOutFunc}
                                />
                            </div>
                        </div>
                    )}

                    {!user && (
                        <div className="absolute inset-y-0 right-4 flex items-center">
                            <div className="block sm:hidden md:mr-6 self-center justify-end">
                                <div className="flex items-center">
                                    <SiteNavigation />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="md:hidden">
                <AppDrawer
                    isOpen={mobileMenuOpen}
                    onClick={handleMobileMenuOpen}
                />
            </div>
        </nav>
    );
}

export default Header;
