import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import routes from '@/services/routes';

import Sidebar from './Sidebar';

function AppSidebar({ isLoggedIn = false }) {
    const router = useRouter();
    const [addingAccount, setAddingAccount] = useState(false);

    useEffect(() => {
        if (router) {
            const isAddingAccount = router.asPath.startsWith(routes.ADD_ACCOUNT);
            setAddingAccount(isAddingAccount);
        }
    }, [router]);

    return isLoggedIn && !addingAccount
        ? (
            <div className="md:flex flex-col md:flex-row">
                <div className="h-full flex flex-col w-full md:w-60 text-shadeStoneGray bg-cleanSlate flex-shrink-0 p-3">
                    <Sidebar />
                </div>
            </div>
        ) : null;
}

export default AppSidebar;
