import { atom, selector } from 'recoil';

import { type SocialLinks, type WorkHistoryItem } from '@/components/settings/Actions';
import { type UserProfileImageUrl } from '@/services/user';

const initializedAtom = atom({
    key: 'initializedState',
    default: false as boolean,
});

export enum BioCandidateStatusEnum {
    Pending = 'pending',
    NoData = 'nodata',
    Running = 'running',
    Failed = 'failed',
    Succeeded = 'succeeded',
    Duplicate = 'duplicate',
    Selected = 'selected',
    Rejected = 'rejected',
    Hallucination = 'hallucination',
}

export enum BioLengthEnum {
    Short = 'short',
    Moderate = 'moderate',
    Long = 'long',
}

export enum BioPerspectiveEnum {
    FirstPerson = 'first',
    ThirdPerson = 'third',
}

export enum BioRefinementStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Succeeded = 'succeeded',
    Hallucination = 'hallucination',
    NoData = 'nodata',
}

export enum BioToneEnum {
    Professional = 'professional',
    Casual = 'casual',
    Factual = 'factual',
}

export interface BioCandidate {
    id: string;
    bio: string;
    documentsSubset: number[];
    status: BioCandidateStatusEnum;
    createdAt: Date;
    updatedAt: Date;
}

export interface BioRefinement {
    id: string;
    bio: string;
    bioCandidate: BioCandidate;
    input: string;
    length: BioLengthEnum;
    perspective: BioPerspectiveEnum;
    status: BioRefinementStatusEnum;
    tone: BioToneEnum;
    temperature: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface BioBatch {
    id: string | number;
    batchJobId: string;
    candidates: Array<BioCandidate>;
    documents: number[];
    status: BioCandidateStatusEnum;
    createdAt: Date;
    updatedAt: Date;
}

export interface Brief {
    id: any;
    name: any;
    bioBatch: BioBatch;
    displayKey: string;
    emailHash: string;
    biography: string;
    canonicalKey: string;
    birthday: string | Date;
    meetingPreferences: string;
    communicationPreferences: string;
    company: string;
    fullName: string;
    industry: string;
    location: string;
    occupation: string;
    title: string;
    role: string;
    skippedBioGeneration: boolean;
    relationshipStatus: string;
    socialLinks: SocialLinks;
    profileImageUrl: UserProfileImageUrl;
    displayWorkHistory: boolean;
    randomlySelected: boolean;
    bioBatchId: any;
    workHistoryItems: Array<WorkHistoryItem>;
    userId: any;

    createdAt: string;
    updatedAt: string;
}
export interface Doc {
    id: any;
    name: string;
}

export interface UserContent {
    id: string | number;

    // User data
    state: string;
    deleteAt: Date;
    birthday: Date;
    email: string;
    emailHash: string;
    fullName: string;
    industry: string;
    location: string;
    occupation: string;
    role: string;
    heardFrom: string;

    // Onboarding / Settings
    onboardingState: number;
    recognizeSameDomainUsers: boolean;
    enableArchival: boolean;
    slowArchivalPeriod: number;
    fastArchivalPeriod: number;
    enableYesterdaysInbox: boolean;
    timezoneName: string;
    timezoneOffset: number;
    productIds: Array<number>;
    receiveProductUpdates: boolean;

    birthdayEmailFrequency: string;
    birthdayEmailMaxBirthdays: number;
    birthdayEmailReminderDays: number;
    birthdayEmailContactType: string;
    birthdayEmailSort: string;

    createdAt: string;
    expectedUsageKind: string;
    expectedUsageOther: string;
    vacationMode: boolean;
    hasCreatedBio: boolean;
    hasCreatedAlerts: boolean;
    hasCreatedAgendas: boolean;
    hasReviewedCalendars: boolean;
    hasCreatedFolders: boolean;
    usesSuperhuman: boolean;
    usesSanebox: boolean;
    setupReminderAt: Date;
    title: string;
    bioOnly: boolean;
    socialLinks: SocialLinks;
    applySetupMode?: boolean;
    profileImageUrl: UserProfileImageUrl;
    company: string;

    // Associations
    bioBatches: Array<BioBatch>;
    briefs: Array<Brief>;
    documents: Array<Doc>;
    workHistoryItems: Array<WorkHistoryItem>;
}

// TODO: Flesh this out.
export interface AccountContent {
    id: string;

    email: string;

    type: string;
    kind: string;

    name: string;

    active: boolean;
    authorized: boolean;
    enabled: boolean;
}

const userAtom = atom({
    key: 'userData',
    default: {} as UserContent,
});

const selectedBriefAtom = atom({
    key: 'selectedBrief',
    default: null,
});

const defaultBlankUserAtom = selector({
    key: 'defaultBlankUserAtom',
    get: ({ get }) => {
        const user = get(userAtom);
        return user || {} as UserContent;
    },
});

const onboardingRemindLater = selector({
    key: 'onboardingRemindLater',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);
        return new Date(user.setupReminderAt) > new Date();
    },
});

const showBioOnboarding = selector({
    key: 'showBioOnboarding',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);
        const remindLater = get(onboardingRemindLater);
        return !remindLater && !user.hasCreatedBio;
    },
});

const showAgendaOnboarding = selector({
    key: 'showAgendaOnboarding',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);
        const remindLater = get(onboardingRemindLater);
        return !remindLater && !user.hasCreatedAgendas;
    },
});

const showAlertOnboarding = selector({
    key: 'showAlertOnboarding',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);
        const remindLater = get(onboardingRemindLater);

        return !remindLater && !user.hasCreatedAlerts;
    },
});

const showFolderOnboarding = selector({
    key: 'showFolderOnboarding',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);
        const remindLater = get(onboardingRemindLater);

        return !remindLater && !user.hasCreatedFolders;
    },
});

const onboardingComplete = selector({
    key: 'onboardingComplete',
    get: ({ get }) => {
        const user = get(defaultBlankUserAtom);

        return user.hasCreatedBio
            && user.hasCreatedAlerts
            && user.hasCreatedAgendas
            && user.hasCreatedFolders;
    },
});

const accountsAtom = atom({
    key: 'accountData',
    default: [] as AccountContent[],
});

const serverDownErrorAtom = atom({
    key: 'serverDownErrorState',
    default: false as boolean,
});

export interface CalendarInstanceContent {
    id: string;

    effectiveLabel: string;
    label: string;
    summary: string;

    visible: boolean;

    primary: boolean;
    writable: boolean;
    categories?: Array<string>;
}

export interface UpdateCalendarInstanceInput {
    id: string;
    attributes: Partial<CalendarInstanceContent>;
}

const calendarInstancesAtom = atom({
    key: 'calendarInstanceData',
    default: [] as CalendarInstanceContent[],
});

const onboardingAtom = atom({
    key: 'onboardingData',
    default: {
        // just an arbitrary "step" counter we can use to know which
        // tooltip we should be showing on these screens, these should reset
        // when the user refreshes the page unless they click "I'm done"
        agenda: 1,
        folders: 1,
        alert: 1,
        type: 'agenda',
        agendaAddEdit: {
            wasAdd: false,
            id: null,
        },
    },
});

export {
    accountsAtom,
    calendarInstancesAtom,
    initializedAtom,
    onboardingAtom,
    serverDownErrorAtom,
    userAtom,
    defaultBlankUserAtom,
    onboardingRemindLater,
    showBioOnboarding,
    showAgendaOnboarding,
    showAlertOnboarding,
    showFolderOnboarding,
    onboardingComplete,
    selectedBriefAtom,
};
