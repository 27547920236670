import { gql } from '@apollo/client';

import { COMMON_BRIEF_FIELDS, COMMON_USER_FIELDS } from '@/types/globals';
import { handleFetchErrors, client } from '@/services/apollo';
import { Brief, UserContent } from '../with_auth/State';

export function transformException(error) {
    return { _: [error] };
}

export const UPDATE_USER = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
        updateUser(input: $updateUserInput) {
            clientMutationId
            user {
                ${COMMON_USER_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

export const CREATE_WORK_HISTORY = gql`
    mutation CreateWorkHistoryItem($createWorkHistoryItemInput: CreateWorkHistoryItemInput!) {
        createWorkHistoryItem(input: $createWorkHistoryItemInput){
            clientMutationId
            workHistoryItem {
                description
                organization
                title
                startAt
                endAt
            }
        }
    }
`;

export const DELETE_WORK_HISTORY = gql`
    mutation DeleteWorkHistoryItem($deleteWorkHistoryItemInput: DeleteWorkHistoryItemInput!){
        deleteWorkHistoryItem(input: $deleteWorkHistoryItemInput){
            clientMutationId
            workHistoryItem {
                id
            }
        }
    }
`;

export const EDIT_WORK_HISTORY = gql`
    mutation UpdateWorkHistoryItem($updateWorkHistoryItemInput: UpdateWorkHistoryItemInput!){
        updateWorkHistoryItem(input: $updateWorkHistoryItemInput){
            clientMutationId
            workHistoryItem {
                description
                organization
                title
                startAt
                endAt
                id
            }

        }
    }

`;

export const CREATE_BRIEF = gql`
    mutation CreateBrief($input: CreateBriefInput!) {
        createBrief(input: $input) {
            clientMutationId
            brief {
                ${COMMON_BRIEF_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

export const UPDATE_BRIEF = gql`
    mutation UpdateBrief($input: UpdateBriefInput!) {
        updateBrief(input: $input) {
            clientMutationId
            brief {
                ${COMMON_BRIEF_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

interface UserUpdateItem {
    user: UserContent;
    setUser: (user: UserContent) => void;
}
/**
 * Handles updating the brief state so we can reuse this call instead
 * of creating it again and again in every component. If the component needed
 * to change or re-render in response to this server call, we'd probably
 * want to switch and use useMutation in the component for more flexibility.
 */
export const updateBriefDirect = async (
    id: string,
    attributes: Partial<Brief>,
    userUpdateItem: UserUpdateItem,
) => {
    try {
        const response: any = await client.mutate({
            mutation: UPDATE_BRIEF,
            variables: {
                input: {
                    id,
                    attributes,
                },
            },
        });

        const newBrief = response.data?.updateBrief?.brief;

        if (newBrief && userUpdateItem) {
            // update the brief on the user
            const newUser = { ...userUpdateItem.user };
            newUser.briefs = newUser.briefs.map((item) => {
                if (item.id === newBrief.id) {
                    return newBrief;
                }
                return item;
            });
            // save to recoil
            userUpdateItem.setUser(newUser);
        }

        return response;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // log to sentry
        handleFetchErrors(error.message);
    }

    return null;
};

export const DELETE_BRIEF = gql`
    mutation DeleteBrief($input: DeleteBriefInput!) {
        deleteBrief(input: $input) {
            clientMutationId
            brief {
                id
                displayKey
            }
        }
    }

`;

export const CREATE_BIO_BATCH = gql`
    mutation CreateBioBatch($input: CreateBioBatchInput!) {
        createBioBatch(input: $input) {
            bioBatch {
                id
                documents
                batchJobId
                status
                candidates {
                    id
                    documentsSubset
                    status
                    bio
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            errors {
                path
                message
            }
        }
    }
`;

export const FETCH_BIO_BATCH = gql`
    query BioBatches {
        user {
            bioBatches {
                id
                documents
                batchJobId
                status
                candidates {
                    id
                    documentsSubset
                    status
                    bio
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            bioRefinements {
                id
                bio
                bioCandidate {
                    id
                    bio
                    status
                }
                input
                length
                perspective
                status
                tone
                temperature
                createdAt
            }
        }
    }
`;

export const UPDATE_BIO_CANDIDATE = gql`
    mutation UpdateBioCandidate($input: UpdateBioCandidateInput!) {
        updateBioCandidate (input: $input) {
            candidate {
                id
                documentsSubset
                status
                bio
                createdAt
                updatedAt
            }
            errors {
                path
                message
            }
        }
    }
`;

export const FETCH_WORK_HISTORY = gql`
    query WorkHistory {
        user {
            workHistoryItems {
                id
                organization
                description
                title
                startAt
                endAt
                createdAt
                updatedAt
            }
        }
    }
`;

export const FETCH_SETUP_COMPLETITION_AND_CIRCLES = gql`
    query getSetupStatus {
        user {
            id
            heardFrom
            state
            relationshipStatus
            accounts {
                id
                type
                setupStatus
            }
            unifiedContacts {
                id
                givenName
                chosenCircle
                proposedCircle
                effectiveCircle
                primaryEmail
                proposedHuman
                chosenHuman
                photoImageUrl
            }
        }
    }
`;

export const FETCH_INTERACTIONS_7_DAYS = gql`
    query Interactions7Days($unifiedContactIds: [ID!]!) {
        interactions7Days(unifiedContactIds: $unifiedContactIds) {
            unifiedContactId
            interactionsCount
            proposedCircle
            chosenCircle
        }
   }
`;
