// Forward references for things we may need before `routes` is defined.
const accountsPath = '/accounts';
const bioDocumentsPath = '/uploads/documents';
const bioPhotosPath = '/uploads/user_photos';
const authorizeAccount = '/onboarding/authorize-account';
const connectedAccounts = '/onboarding/connected-accounts';

// WARNING:  `process.env.NEXT_*` is unavailable when building Storybook statically!  There needs to
// be a fallback for these values.

const corpRoot = process.env.NEXT_PUBLIC_CORP_ROOT || 'https://sixty.app';

const helpRoot = process.env.NEXT_PUBLIC_HELPSCOUT_ROOT || 'https://help.sixty.app';

const frontendRootRaw = process.env.NEXT_PUBLIC_FRONTEND_ROOT || 'http://localhost:8000';
const frontendRoot = () => (new URL(frontendRootRaw));

const backendRootRaw = process.env.NEXT_PUBLIC_BACKEND_ROOT || 'http://localhost:5000';
const backendRoot = () => (new URL(backendRootRaw));

const shortlinkRootRaw = process.env.NEXT_PUBLIC_FRONTEND_LINK_DOMAIN || 'localhost:8000';
const shortlinkRoot = () => (new URL(`${frontendRoot().protocol}//${shortlinkRootRaw}`));

const buildFullFrontendURL = (relativePath: string) => {
    const tmpURL = frontendRoot();
    tmpURL.pathname = relativePath;
    return tmpURL.toString();
};

type AuthProvider = 'google' | 'microsoft_graph';
// Generalized for non-Google providers:
const backendOmniauthPath = (provider: AuthProvider) => `/auth/${provider}`;
// For Google specifically:
const backendAuthPath = (provider: AuthProvider) => `/auth/${provider}/authorize`;
const backendLoginPath = (provider: AuthProvider) => `/auth/${provider}/login`;
const backendSignupPath = (provider: AuthProvider) => `/auth/${provider}/signup`;

// NOTE: Make sure that there's a corresponding file in `src/pages`, with the same name as the
// route.  This will make it possible to generate static pages without having to parse this file.
//
// IMPORTANT: Any route referenced in `sidebar.tsx` almost certainly needs a trailing slash to be
// present here!
const routes = {
    CORP: corpRoot,
    TERMS_AND_CONDITIONS: `${corpRoot}/terms/`,
    PRIVACY_POLICY: `${corpRoot}/privacy/`,

    ROOT: '/',

    BRIEF_PREFIX: '/brief', // Not an actual route!
    BIO_PREFIX: '/bio/', // Not an actual route!
    BIO_OVERVIEW: '/bio/overview',
    BIO_AUTHORIZE: '/bio/authorize',
    BIO_PERSONALIZE: '/bio/personalize',
    BIO_CAREER_HISTORY: '/bio/career-history',
    BIO_SELECT_IMPORT_METHOD: '/bio/select-import-method',
    BIO_MANUAL_CAREER_HISTORY: '/bio/manual-job-history',
    BIO_SELECT_STARTER_BIO: '/bio/select-starter-bio',
    BIO_UPLOAD_FILE_MODAL: '/bio/upload',
    BIO_REVISE_BIO: '/bio/revise-bio',
    BIO_EDIT_BIO: '/bio/edit-bio',
    BIO_MEETING_PREFERENCES: '/bio/meeting-preferences',
    BIO_COMMUNICATION_PREFERENCES: '/bio/communication-preferences',
    BIO_SOCIAL_LINKS: '/bio/social-links',
    BIO_REVIEW_BIO: '/bio/review-your-bio',
    BIO_COMPLETE_SHARE: '/bio/share',
    ONBOARDING_PREFIX: '/onboarding/', // Not an actual route!
    ONBOARDING_HOW_SIXTY_WORKS: '/onboarding/how-sixty-works',
    ONBOARDING_ADD_ACCOUNT: '/onboarding/add-account',
    ONBOARDING_ANALYZING_DATA: '/onboarding/analyzing-data',
    ONBOARDING_AUTHORIZE_ACCOUNT: authorizeAccount,
    ONBOARDING_AUTHORIZE_ACCOUNT_URL: buildFullFrontendURL(authorizeAccount),
    ONBOARDING_CONNECTED_ACCOUNTS: connectedAccounts,
    ONBOARDING_CONNECTED_ACCOUNTS_URL: buildFullFrontendURL(connectedAccounts),
    ONBOARDING_INVITE: '/onboarding/invite',
    // Do these need trailing slashes, they seem to be redirected to
    // non trailing?

    SIGNUP_RETURN_URL: buildFullFrontendURL('/onboarding/how-sixty-works'),

    CALENDAR: '/calendar',
    ALERTS: '/calendar/alerts',
    ADD_ALERT: '/calendar/alerts/add',
    EDIT_ALERT: '/calendar/alerts/edit',
    AGENDAS: '/calendar/agendas',
    ADD_AGENDA: '/calendar/agendas/add',
    EDIT_AGENDA: '/calendar/agendas/edit',
    BIRTHDAYS: '/calendar/birthdays',
    ADD_BIRTHDAY: '/calendar/birthdays/add',
    EDIT_BIRTHDAY: '/calendar/birthdays/edit',
    BIO: '/calendar/bio',

    INBOX: '/inbox',
    MONITORED_LABELS: '/inbox/monitored-labels',
    ADD_MONITORED_LABELS: '/inbox/monitored-labels/add',
    EDIT_MONITORED_LABELS: '/inbox/monitored-labels/edit',
    MAIL_RULES: '/inbox/mail-rules',
    EMAIL_DOMAINS: '/inbox/email-domains',
    ADD_EMAIL_DOMAINS: '/inbox/email-domains/add',
    EDIT_EMAIL_DOMAINS: '/inbox/email-domains/edit',

    INVITE_FRIENDS: '/invite',

    SCHEDULER: '/scheduler',
    JOIN_SCHEDULE: '/scheduler/join-schedule',
    CREATE_GROUP_MEETING: '/scheduler/create-group-meeting',
    JOIN_GROUP_MEETING: '/scheduler/join-group-meeting',
    VIEW_GROUP_MEETING: '/scheduler/view-group-meeting',

    ENGAGEMENT: '/engagement',
    OTHER: '/other',

    SETTINGS: '/settings',

    ACCOUNTS: accountsPath,
    ACCOUNTS_URL: buildFullFrontendURL(accountsPath),

    LOGIN: '/login',
    SIGN_UP: '/sign-up',
    CHOOSE_METHOD: '/choose-method',
    ADD_ACCOUNT: '/add-account',
    MAGIC_LINK_SENT: '/magic-link-signup/magic-link-sent',
    DATA_ACCESS: '/magic-link-signup/data-access',

    MY_SIXTY: '/my-sixty',
    MY_NETWORK: '/my-network',

    CONTACTS_GAME: '/contacts-game',

    FRONTEND_URL: (target) => {
        if (target === null) {
            return null;
        }

        const tmpURL = frontendRoot();
        tmpURL.pathname = target;
        return tmpURL.toString();
    },

    LOGIN_AND_RETURN_TO: (target) => {
        const tmpURL = frontendRoot();
        tmpURL.pathname = routes.LOGIN;
        const loginParams = new URLSearchParams();
        loginParams.append('target', target);
        tmpURL.search = loginParams.toString();
        return tmpURL.toString();
    },

    // Routes to the back-end of the app:
    GOOGLE_OAUTH_LOGIN: (provider, target) => {
        const tmpURL = backendRoot();
        tmpURL.pathname = backendLoginPath(provider);
        const addAccountParams = new URLSearchParams();
        addAccountParams.append('target', target);
        tmpURL.search = addAccountParams.toString();
        return tmpURL.toString();
    },

    OMNI_OAUTH: (provider) => {
        const tmpURL = backendRoot();
        tmpURL.pathname = backendOmniauthPath(provider);
        return tmpURL.toString();
    },

    SIGNUP_OAUTH: (provider, target, termsAccepted, analytics) => {
        const tmpURL = backendRoot();
        tmpURL.pathname = backendSignupPath(provider);
        const addAccountParams = new URLSearchParams();
        addAccountParams.append('target', target);
        addAccountParams.append('terms_accepted', termsAccepted);
        if (analytics.sourceKey) {
            addAccountParams.append('source_key', analytics.sourceKey);
        }
        if (analytics.benefitsKey) {
            addAccountParams.append('benefits_key', analytics.benefitsKey);
        }
        tmpURL.search = addAccountParams.toString();
        return tmpURL.toString();
    },

    REFRESH_GOOGLE_ACCOUNT: (hint, target, onboarding) => {
        const refreshAccountURL = backendRoot();
        refreshAccountURL.pathname = backendAuthPath('google');
        const refreshAccountParams = new URLSearchParams();
        refreshAccountParams.append('target', target);
        refreshAccountParams.append('hint', hint);
        if (onboarding) {
            refreshAccountParams.append('onboarding', 'true');
        }
        refreshAccountURL.search = refreshAccountParams.toString();
        return refreshAccountURL.toString();
    },

    REFRESH_OAUTH_ACCOUNT: (provider, hint, target) => {
        const refreshAccountURL = backendRoot();
        refreshAccountURL.pathname = backendAuthPath(provider);
        const refreshAccountParams = new URLSearchParams();
        refreshAccountParams.append('target', target);
        refreshAccountParams.append('login_hint', hint);
        refreshAccountURL.search = refreshAccountParams.toString();
        return refreshAccountURL.toString();
    },

    BIO_UPLOAD: (() => {
        const bioDocumentsURL = backendRoot();
        bioDocumentsURL.pathname = bioDocumentsPath;
        return bioDocumentsURL.toString();
    })(),

    PHOTOS_UPLOAD: () => {
        const bioPhotosURL = backendRoot();
        bioPhotosURL.pathname = bioPhotosPath;
        return bioPhotosURL.toString();
    },

    BRIEF_URL: (key) => {
        const briefURL = shortlinkRoot();
        briefURL.pathname = key;
        return briefURL.toString();
    },

    HELP: helpRoot,
    HELP_PAGES: {
        GETTING_STARTED: `${helpRoot}/category/13-cat1`,
        EMAIL_SIGNATURE: `${helpRoot}/article/81-adding-your-sixty-brief-to-your-email-signature`,
    },

    STATUS_PAGE: 'https://sixty.info/',
};

export default routes;

export { frontendRoot, backendRoot };
